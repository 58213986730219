
import React, { useState, useEffect } from 'react';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, Form, Button } from 'react-bootstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Sidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import $, { data } from 'jquery';
echarts.use([PieChart, TooltipComponent, TitleComponent, CanvasRenderer]);

const Analysis = () => {
  const [loading, setLoading] = useState(false); // New state for loading indicator
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [projectName, setProjectName] = useState('');
  const [city, setCity] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [builderNameInput, setBuilderNameInput] = useState('');
  const [companyNameInput, setCompanyNameInput] = useState('');
  const [fileInput, setFileInput] = useState(null);
  const [showRegisterPropertyModal, setShowRegisterPropertyModal] = useState(false);
  const [property_name, setPropertyName] = useState('');
  const [company_name, setCompanyName] = useState('');
  const [propertyNameError, setPropertyNameError] = useState('');
const [companyNameError, setCompanyNameError] = useState('');
const [projectsList, setProjectsList] = useState([]);
const [cityList, setCityList] = useState([]);
const [formSubmitted, setFormSubmitted] = useState(false);
const [formSubmitted1, setFormSubmitted1] = useState(false);

useEffect(() => {
  const fetchData = async () => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }

      const response = await fetch(`${baseURL}/get_all_properties/`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
        },
      });

      if (!response.ok) {
        $('#loading').hide();
      }

      const data = await response.json();
      console.log(data); // Log the received data
      setProjectsList(data.projects);
      setCityList(data.citis);
      $('#loading').hide();
    } catch (error) {
      console.error('Error fetching projects:', error);
      $('#loading').hide();
    }
  };

  fetchData();
}, []);


  useEffect(() => {
    updateChartWithData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateChartWithData();
  };

  const updateChartWithData = (apiData) => {
    console.log(apiData)
    const trafficChart = echarts.init(document.getElementById('trafficChart'));
    trafficChart.setOption({
      tooltip: { trigger: 'item' },
      legend: { top: '5%', left: 'center' },
      series: [{
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: { show: false, position: 'center' },
        emphasis: { label: { show: true, fontSize: '18', fontWeight: 'bold' } },
        labelLine: { show: false },
        data: apiData // Replace 'data' with the API response data
      }]
    });
  };

  const handleImportData = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBuilderNameChange = (e) => {
    setBuilderNameInput(e.target.value);
  };

  

  const handleFileChange = (e) => {
    setFileInput(e.target.files[0]);
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault(true);
    const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      $('#loading').hide();
      return;
    }
  
    const formData = new FormData();
    formData.append(' ', builderNameInput);
    formData.append('companyName', companyNameInput);
    formData.append('file', fileInput);
  
    try {
      $('#loading').show();
      const response = await fetch(`${baseURL}/upload_property_data/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
        },
        body: formData,
      });
  
      if (response.ok) {
        console.log('Form data sent successfully');
        toastr.success('File uploaded successfully!');
        setBuilderNameInput(null);
        setCompanyNameInput('');
        setShowModal(false);
        $('#loading').hide();
      } else {
        console.error('Error sending form data');
        toastr.error('Something went wrong');
        setBuilderNameInput(null);
        setCompanyNameInput('');
        $('#loading').hide();
      }
    } catch (error) {
      console.error('Error:', error);
      $('#loading').hide();
    }
  
    setShowModal(false);
  };
  

  const handleRegisterProperty = () => {
    setShowRegisterPropertyModal(true);
  };

  const handlePropertySubmit = async (e) => {
    e.preventDefault();
    
    const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      $('#loading').hide();
      return;
    }
  
    const propertyData = {
      property_name,
      company_name,
    };
  
    try {
      $('#loading').show();
      const response = await fetch(`${baseURL}/register_property/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
          // You might need to include additional headers based on your API requirements
        },
        body: JSON.stringify(propertyData),
      });
  
      if (response.ok) {
        toastr.success('Property added successfully!');
        $('#loading').hide();
        setShowRegisterPropertyModal(false);
        
        navigate('/analysis');
        window.location.href = '/analysis';
        
      } else {
        $('#loading').hide();
        const data = await response.json();
        if (data.property_name) {
          setPropertyNameError(data.property_name[0]);
        } else {
          setPropertyNameError('');
        }
        if (data.company_name) {
          setCompanyNameError(data.company_name[0]);
        } else {
          setCompanyNameError('');
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error registering property:', error);
      $('#loading').hide();
    }
  };
  

  const handlePropertyNameChange = (e) => {
    const value = e.target.value;
    setPropertyName(value);
  
    // Validate the input if needed and update the error state
    if (value.trim() === '') {
      setPropertyNameError('Property Name is required');
    } else {
      setPropertyNameError('');
    }
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
  
    // Validate the input if needed and update the error state
    if (value.trim() === '') {
      setCompanyNameError('Company Name is required');
    } else {
      setCompanyNameError('');
    }
  };
  
  const handleCloseModal1 = () => {
    setShowModal(false);
    setBuilderNameInput('');
    setCompanyNameInput('');
    setPropertyName('');
    setCompanyName('');
    setPropertyNameError('');
    setCompanyNameError('');
  };

  const handleSubmitform = () => {
    if (!projectName || !city || !fromDate || !toDate || !selectedType) {
      setFormSubmitted(true);
      return;
    }
  
    // Reset formSubmitted if all fields are filled
    setFormSubmitted(false);
    
    const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      $('#loading').hide();
      return;
    }
  
    // Prepare your data object to be sent in the POST request
    const requestData = {
      projectName,
      city,
      fromDate,
      toDate,
      selectedType,
    };
    $('#loading').show();
    // Make a POST request using fetch
    fetch(`${baseURL}/generate_chart_data/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${accessToken}`,
        'Cache-Control': 'no-cache', // Include access token in the headers
        // Add any necessary headers here
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response here, data will contain the response from the API
        // For example, update your chart with the received data
        console.log(data);
        updateChartWithData(data.data);
        $('#loading').hide();
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error:', error);
        $('#loading').hide();
      });
  };
  
  const handleFromDateChange = (date) => {
    const selectedDate = new Date(date.setHours(0, 0, 0, 0));
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    const selectedDate = new Date(date.setHours(0, 0, 0, 0));
    setToDate(date);
  };

  const handleFetchLeads = async () => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      $('#loading').hide();
      return;
    }
      const response = await fetch(`${baseURL}/v1/fetch_leads/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache',
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        console.log('Fetched leads:', data);
        console.log(data.message)
        $('#loading').hide();
        toastr.success(data.message)
      } else {
        console.error('Failed to fetch leads:', response.statusText);
        $('#loading').hide();
        toastr.error(data.message)
      }
    } catch (error) {
      console.error('Fetch error:', error);
      $('#loading').hide();
      
    }
  };

  const handlesendLeads = async () => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      $('#loading').hide();
      return;
    }
      const response = await fetch(`${baseURL}/v1/send_leads_by_wati/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache',
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Handle the retrieved data
        console.log('Fetched leads:', data);
        $('#loading').hide();
        toastr.success(data.message)
      } else {

        const data = await response.json();
        console.error('Failed to fetch leads:', response.statusText);
        $('#loading').hide();
        toastr.success(data.message)
        
      }
    } catch (error) {
      console.error('Fetch error:', error);
      $('#loading').hide();
      
    }
  };
  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 p-0">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="card mt-5">
            <div className="card-body d-flex">
            <div id="loading">
            <div class="loader">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="loader-text">Loading...</div>
            </div>
          </div>
              <div className="flex-fill">
              <button type="button" className="btn btn-primary me-2" onClick={handleImportData}>
                  Import Data
                </button>
                <button type="button" className="btn btn-primary" onClick={handleRegisterProperty}>
                  Add Property
                </button>

                <Modal show={showRegisterPropertyModal} onHide={() => {
  setShowRegisterPropertyModal(false);
  handleCloseModal1(); // Reset the state values for this specific modal
}}>                  <Modal.Header closeButton>
                    <Modal.Title>Register Property</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handlePropertySubmit}>
                    <Form.Group controlId="propertyName">
  <Form.Label>Property Name</Form.Label>
  <Form.Control
    type="text"
    placeholder="Enter property name"
    value={property_name}
    onChange={handlePropertyNameChange}
  />
  {propertyNameError && <span className="text-danger">{propertyNameError}</span>}
</Form.Group>
<Form.Group controlId="companyName">
  <Form.Label>Company Name</Form.Label>
  <Form.Control
    type="text"
    placeholder="Enter company name"
    value={company_name}
    onChange={handleCompanyNameChange}
  />
  {companyNameError && <span className="text-danger">{companyNameError}</span>}
</Form.Group>
                    </Form>
                  </Modal.Body>
                 
                  <Modal.Footer className="justify-content-center">
                    
                  <Button variant="secondary" onClick={() => {
                    setShowRegisterPropertyModal(false);
                    // Reset state values for this specific modal
                    setPropertyName('');
                    setCompanyName('');
                    setPropertyNameError('');
                    setCompanyNameError('');
                  }}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handlePropertySubmit}>
                  
                    Submit
                  </Button>
                </Modal.Footer>
                </Modal>
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Import Data</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                    <Form.Select
  aria-label="Builder Name"
  value={builderNameInput}
  onChange={handleBuilderNameChange}
>
  <option value="">Select Builder Name</option>
  {Array.isArray(projectsList) &&
    projectsList.map((property_name) => (
      <option key={property_name.id} value={property_name.value}>
        {property_name.property_name}
      </option>
    ))}
</Form.Select>
<br></br>

                      <Form.Group controlId="file">
                        <Form.Label>Attach File</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleModalSubmit}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
                <div className="card bg-light mb-3 ml-3 mt-3" >
    <div className="card-body">
      <h5 className="card-title"></h5>
      <div className="mb-3">
      <strong>
    <Form.Label className="text-dark">Select Project<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
      <select
  value={projectName}
  onChange={(e) => setProjectName(e.target.value)}
  className="form-select mb-3"
  required 
>
  <option value="">Select Project</option>
  {Array.isArray(projectsList) &&
    projectsList.map((project) => (
      <option key={project.id} value={project.id}>
        {project.property_name}
      </option>
    ))}
</select>


<strong>
    <Form.Label className="text-dark">Select City<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
<select
  value={city}
  onChange={(e) => setCity(e.target.value)}
  className="form-select mb-3"
  required 
>
  <option value="">Select City</option>
  <option value="All">All</option>
  {Array.isArray(cityList) &&
    cityList.map((city) => (
      <option key={city.id} value={city.id}>
        {city}
      </option>
    ))}
</select>


        
<div className="mb-3">
<strong>
    <Form.Label className="text-dark">From Date:<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
        <DatePicker
          id="fromDate"
          className="form-control"
          selected={fromDate}
          onChange={handleFromDateChange}
          dateFormat="dd/MM/yyyy"
          showTimeInput={false}
          required 
        />
      </div>
      <div className="mb-3">
      <strong>
    <Form.Label className="text-dark">To Date:<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
        <DatePicker
          id="toDate"
          className="form-control"
          selected={toDate}
          onChange={handleToDateChange}
          dateFormat="dd/MM/yyyy"
          showTimeInput={false}
          required 
        />
      </div>
      <strong>
    <Form.Label className="text-dark">Type<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
        <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} className="form-select mb-3" required>
          <option value="">Select Type</option>
          <option value="All">All</option>
          <option value="Lead">Lead</option>
          <option value="Requirement">Requirement</option>
        </select>
        
        <div className="card-body text-center">
        
      {/* ... (other form elements) */}
      <button type="submit" className="btn btn-success mt-3" onClick={handleSubmitform}>Submit</button>
    </div>

      </div>
     
    </div>
  </div>
</div>

              <div className="flex-fill">
              <div className="d-flex justify-content-end mb-3 ms-5 me-5">
                <button type="submit" className="btn btn-primary me-2" onClick={handleFetchLeads}>Fetch Leads</button>
              
              <button type="submit" className="btn btn-primary me-2" onClick={handlesendLeads}>Send Leads</button>
                </div>
              
                  
    <div className="card bg-light mb-3 ms-5 mt-3 me-5">
      
    <div className="card-body pb-0">
      <h5 className="card-title">Result For Builder TMS</h5>
      <div id="trafficChart" style={{ minHeight: "400px" }}></div>
    </div>
  </div>

</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
