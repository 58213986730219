import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'; 
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

const SendLeads = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [builderTypes, setBuilderTypes] = useState([]);
  const [selectedBuilderType, setSelectedBuilderType] = useState('');
  const [errors, setErrors] = useState({});
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showTimeFields, setShowTimeFields] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }

        const response = await fetch(`${baseURL}/get_ads/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Cache-Control': 'no-cache',
          },
        });

        if (!response.ok) {
          $('#loading').hide();
        }

        const data = await response.json();
        if (data && data.data) {
          setBuilderTypes(data.data);
          $('#loading').hide();
        }
      } catch (error) {
        console.error('Error fetching builder types:', error);
        setErrors({ selectedBuilderType: 'Error fetching builder types' });
        $('#loading').hide();
      }
    };

    fetchData();
  }, []);

  const handleBuilderTypeChange = (event) => {
    const value = event.target.value;
    setSelectedBuilderType(value);

    // "All Leads" ya kisi bhi ad select hone pe date-time fields show honge
    if (value === 'all_ads' || value !== '') {
      setShowTimeFields(true);
    } 
    // Agar "Select Ad" choose kare to fields hide ho jayenge aur clear honge
    if (value === '') {
      setShowTimeFields(false);
      setStartTime('');
      setEndTime('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }

      const requestBody = {
        ad_name: selectedBuilderType,
        start_time: startTime,
        end_time: endTime,
      };

      const response = await fetch(`${baseURL}/whats_app_lead_send/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Request failed:', errorMessage);
        toastr.error('No Leads Found');
      } else {
        toastr.success('All Leads Sent Successfully');
      }

      // Reset state after submission
      setSelectedBuilderType('');
      setStartTime('');
      setEndTime('');
      setShowTimeFields(false);
      $('#loading').hide();

    } catch (error) {
      toastr.error('Something Went Wrong');
      console.error('Error submitting data:', error);
      $('#loading').hide();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 p-0">
          <Sidebar />
        </div>
        <div className="col-md-9 d-flex justify-content-center align-items-center">
          <div id="loading">
            <div className="loader">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="loader-text">Loading...</div>
            </div>
          </div>

          <div className="card p-4 rounded text-center" style={{ backgroundColor: '#f0f0f0', minWidth: '636px', minHeight: '500px' }}>
            <h2>Send Leads To Whatsapp</h2>
            <div className="mb-3">
              <img src="wa7.png" height="180" alt="WhatsApp Logo" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="ad_name" style={{ fontSize: '25px' }}>Select Ad:</label>
                <hr />
                <select
                  name="ad_name"
                  id="ad_name"
                  className="form-select"
                  required
                  value={selectedBuilderType}
                  onChange={handleBuilderTypeChange}
                >
                  <option value="">Select Ad</option>
                  <option value="all_ads">All Ads</option>
                  {builderTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.builder_name}
                    </option>
                  ))}
                </select>
                {errors.selectedBuilderType && (
                  <div className="error-message text-danger">
                    {errors.selectedBuilderType}
                  </div>
                )}
              </div>

              {/* Show Date & Time Fields When "All Leads" or Specific Ad is Selected */}
              {showTimeFields && (
                <>
                  <div className="mb-3">
                    <label htmlFor="start_time">Start Time:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="start_time"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="end_time">End Time:</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="end_time"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      required
                    />
                  </div>
                </>
              )}

              <div className="d-grid gap-2 col-6 mx-auto">
                <Button variant="success" type="submit" style={{ backgroundColor: '#2ecc71', maxWidth:'100px', marginLeft:'122px'}}>Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendLeads;
