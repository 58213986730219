import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './importlead.css';
import Sidebar from '../Sidebar/Sidebar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate } from 'react-router-dom';
import { RingLoader } from 'react-spinners'; // Import RingLoader here
import $, { data } from 'jquery';
const FileUploadComponent = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [builderTypes, setBuilderTypes] = useState([]);
  const [selectedBuilderType, setSelectedBuilderType] = useState('');
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      $('#loading').show();
      try {
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
  
        const response = await fetch(`${baseURL}/get_ads/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Cache-Control': 'no-cache', // Include access token in the headers
          },
        });
  
        if (!response.ok) {
          $('#loading').hide();
        }
  
        const data = await response.json();
        if (data && data.data) {
          setBuilderTypes(data.data);
          $('#loading').hide();

        }
      } catch (error) {
        console.error('Error fetching builder types:', error);
        setErrors({ selectedBuilderType: 'Error fetching builder types' });
        $('#loading').hide();

      }
    };
  
    fetchData();
  }, []);
  

  const handleUploadClick = () => {
    document.getElementById('call-to-action').classList.add('upload--loading');
    document.querySelector('.upload-hidden').click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsLoading(true); // Show spinner when file upload begins

    const formData = new FormData();
    formData.append('selectedBuilderType', selectedBuilderType);
    formData.append('file', file);
    $('#loading').show();

    fetch(`${baseURL}/import_leads/`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache', // Include access token in the headers
      },
      body: formData,
    })
      .then((response) => {
        $('#loading').hide();
        if (response.ok) {
          
          toastr.success('File uploaded successfully!');
          document.getElementById('call-to-action').classList.remove('upload--loading');
          setSelectedFile(null);
          setSelectedBuilderType('');
          $('#loading').hide();
          
        } else {
          toastr.error('Error uploading file.');
          document.getElementById('call-to-action').classList.remove('upload--loading');
          setSelectedFile(null);
          $('#loading').hide();
        }
      })
      .catch((error) => {
        document.getElementById('call-to-action').classList.remove('upload--loading');
          setSelectedFile(null);
        
        toastr.error('Error:', error.message);
        $('#loading').hide();
      });
  };

  const handleBuilderTypeChange = (event) => {
    setSelectedBuilderType(event.target.value);
  };

  return (
    <div className="page-container">
      <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>
    <div className="d-flex">
      <Sidebar />
      <div className="flex-grow-1">
        <div className="header-container-wrapper">
          <div className="header-container">
            <div className="custom-header-bg">
              <div className="page-center">
                <div className="logo">Import Leads</div>
              </div>
            </div>
          </div>
        </div>

        <div className="body-container-wrapper">
          <div className="body-container">
            <div className="page-center">
              <i className="fa fa-cloud" aria-hidden="true"></i>
              <h1>Upload Your <strong>Files</strong> To TMS Server</h1>
              <hr />
              <select
                aria-label="Select Builder Type"
                className={`form-select mb-1 ${errors.selectedBuilderType ? 'border border-danger' : ''}`}
                value={selectedBuilderType}
                onChange={handleBuilderTypeChange}
                style={{
                  color: 'black',
                  borderColor: 'blue',
                  maxWidth: '438px',
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <option value="">Select Builder Type</option>
                {Array.isArray(builderTypes) &&
                  builderTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.builder_name}
                    </option>
                  ))}
              </select>
              <hr />
              <hr />
              <a className="upload" id="call-to-action" onClick={handleUploadClick}>
                <span>Select Your Upload</span>
              </a>
              <form id="upload" method="post" action="upload.php" encType="multipart/form-data">
                <div id="drop">
                  <input type="file" name="upl" multiple className="upload-hidden" onChange={handleFileInputChange} />
                </div>
              </form>
              
            </div>
          </div>
        </div>

        <div className="footer-container-wrapper">
          <div className="footer-container">
            <div className="custom-footer-bg">
              <div className="page-center">
                <p>@Copyright TMS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FileUploadComponent;
