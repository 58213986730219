import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../Sidebar/Sidebar';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const GruhkhojDashboard = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [showModal, setShowModal] = useState(false);
  const [leadData, setLeadData] = useState(null);
  const [custData, setcustData] = useState(null);


  const handleSubmit = async (e) => {
    setShowModal(false)
    e.preventDefault();
  
    try {
      const response = await fetch(`${baseURL}/send_manual_whatsapp_lead/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        toastr.success("Lead submitted successfully!");
        setShowModal(false);
        setFormData({ customer_name: "", phone: "", email: "", property_type: "", message: "" });
      } else {
        toastr.error(responseData.message || "Failed to submit lead.");
      }
    } catch (error) {
      console.error("Error:", error);
      toastr.error("Something went wrong.");
    }
  };
  
  

  $(document).off('click', '.resend-btn').on('click', '.resend-btn', async function() {
    $('#loading').show();
    const id = $(this).data('id');
    const resendType = $(this).data('type'); // 'builder' or 'customer'

    const button = $(this);
    button.prop('disabled', true); // ✅ Disable button to prevent multiple clicks

    try {
        const response = await fetch(`${baseURL}/get_lead/`, {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('access_token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                lead_id: id,        
                resend_for: resendType 
            }) 
        });

        const responseData = await response.json();  
        console.log('Response JSON:', responseData);  

        if (response.ok) {
            // setLeadData(responseData.data);
            // setcustData(responseData.leads);


            // setShowModal(true);
            toastr.success(`Lead resent successfully for ${resendType}!`);
            $('#loading').hide();
            window.location.reload()
        } else {
            toastr.error(responseData.message || 'Failed to resend lead.');
            $('#loading').hide();
        }
    } catch (error) {
        console.error('Error:', error);
        toastr.error('Something went wrong.');
        $('#loading').hide();
    } finally {
        button.prop('disabled', false); // ✅ Re-enable button after response
        $('#loading').hide();
    }
});


  
  

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found');
          $('#loading').hide();
          return;
        }

        const response = await fetch(`${baseURL}/get_gruhkhoj_leads/?page=${currentPage + 1}&page_size=${pageSize}`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
          },
        });

        if (!response.ok) {
          $('#loading').hide();
          toastr.error('Error fetching leads');
          return;
        }

        const data = await response.json();
        setTableData(data.results);
        setTotalPages(Math.ceil(data.count / pageSize)); // Update total pages
        $('#loading').hide();
        toastr.success('Gruhkhoj Leads Fetched Successfully');
      } catch (error) {
        console.error('Error fetching data:', error);
        toastr.error('Something Went Wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize]); // Fetch data when page or page size changes

  useEffect(() => {
    let dataTable = null;

    if (!loading) {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().clear().destroy();
      }

      dataTable = $(tableRef.current).DataTable({
        data: tableData,
        columns: [
          { title: 'ID', data: 'id' },
          { title: 'Lead ID', data: 'enquiry_id' },
          { title: 'Property', data: 'property_name' },
          { title: 'Date', data: 'created_at' },
          {
            title: 'B Status',
            data: 'is_sent_builder',
            render: function(status, type, row) { 
              return status 
                ? 'Sent' 
                : `<span class="text-danger">Not Sent</span>
                         <button class="btn btn-sm btn-warning resend-btn" data-id="${row.enquiry_id}" data-type="builder">Resend</button>` 
             }
          },
          
          { 
            title: 'C Status', 
            data: 'is_sent_customer', 
            render: function(status, type, row) { 
                return status 
                  ? 'Sent' 
                  : `<span class="text-danger">Not Sent</span> 
         <button class="btn btn-sm btn-warning resend-btn" data-id="${row.enquiry_id}" data-type="customer">Resend</button>` 
              }
          }
        ]
      });
    }

    return () => {
      if (dataTable) {
        dataTable.clear().destroy();
      }
    };
  }, [loading, tableData]);

  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).find('thead th').css('background-color', '#9F9F9F');
    }
  }, [tableData]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <main className="col-md-9 ms-sm-auto col-lg-9 px-md-3">
          <h2 className="text-center mt-3">Welcome To Gruhkhoj Lead Dashboard</h2>
          <div id="loading">
            <div className="loader">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="loader-text">Loading...</div>
            </div>
          </div>

          <div className="table-responsive">
            <table
              id="table_container"
              ref={tableRef}
              className="table table-bordered table-striped"
              style={{ width: '100%' }}
            />
          </div>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
            />

            {/* Page Size Selector */}
            <select
              className="form-select"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="10">10 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
            </select>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)} >
        <Modal.Header closeButton>
          <Modal.Title>Lead Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {leadData && (
            <Form>
              <Form.Group>
                <Form.Label>Builder Name</Form.Label>
                <Form.Control type="text" value={custData.property_name} readOnly />
              </Form.Group>
              {leadData.template_name === 'customer444' ? (
                <>
                <Row>
                <Col>
  <Form.Group>
    <Form.Label>Customer Name</Form.Label>
    <Form.Control 
      type="text" 
      value={custData.id || ''} readOnly
       
    />
  </Form.Group>
</Col>
                <Col>
  <Form.Group>
    <Form.Label>Customer Name</Form.Label>
    <Form.Control 
      type="text" 
      value={custData.customer_name || ''} readOnly
       
    />
  </Form.Group>
</Col>
<Col>
  <Form.Group>
    <Form.Label>Customer Contact</Form.Label>
    <Form.Control 
      type="text" 
      value={custData.phone || ''} readOnly
    />
  </Form.Group>
</Col>
</Row>

<Row>
                <Col>
  <Form.Group>
    <Form.Label>Customer Email</Form.Label>
    <Form.Control 
      type="text" 
      value={custData.email || ''} readOnly
       
    />
  </Form.Group>
</Col>
<Col>
  <Form.Group>
    <Form.Label>Customer Interest</Form.Label>
    <Form.Control 
      type="text" 
      value={custData.property_type || ''} readOnly
       
    />
  </Form.Group>
</Col>
</Row>

<Row>
           
<Col>
  <Form.Group>
    <Form.Label>Customer Message</Form.Label>
    <Form.Control 
      type="text" 
      value={custData.message || ''} readOnly
       
    />
  </Form.Group>
</Col>
</Row>
<Row>
                
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Builder Name 1</Form.Label>
                        <Form.Control type="text" value={leadData.parameters.find(p => p.name === 'name')?.value || ''} readOnly/>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Mobile Number 1</Form.Label>
                        <Form.Control type="text" value={leadData.parameters.find(p => p.name === 'mobile_no')?.value || ''}  readOnly/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Builder Name 2</Form.Label>
                        <Form.Control type="text" value={leadData.parameters.find(p => p.name === 'name1')?.value || ''}  readOnly/>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Mobile Number 2</Form.Label>
                        <Form.Control type="text" value={leadData.parameters.find(p => p.name === 'mobile_no1')?.value || ''} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Form.Group>
                    <Form.Label>Builder Name</Form.Label>
                    <Form.Control type="text" value={leadData.parameters.find(p => p.name === 'name')?.value || ''}  readOnly/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="text" value={leadData.parameters.find(p => p.name === 'mobile_no')?.value || ''} readOnly/>
                  </Form.Group>
                </>
              )}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
        <button type="submit" className="mt-3" variant="primary" onClick={handleSubmit}>Submit</button>


        </Modal.Footer>
      </Modal>
        </main>
      </div>
    </div>
  );
};

export default GruhkhojDashboard;




