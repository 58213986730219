import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../Sidebar/Sidebar';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';


const Dashboard = ({ show, handleClose, initialData }) => {
  const [deleteItemUsername, setDeleteItemUsername] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false); // State to control the visibility of the form
  const [numbers, setNumbers] = useState(['']);
  const [errors, setErrors] = useState({});
  const [selectedBuilderType, setSelectedBuilderType] = useState('');
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [builderTypes, setBuilderTypes] = useState([]);
  const [formData, setFormData] = useState(initialData || {
    adName: '',
    adID: '',
    builderName: '',
    whatsAppNumber: '',
    numbers: [''], // State to manage multiple numbers
    publishDate: '',
  });
  const handleBuilderTypeChange = (event) => {
    setSelectedBuilderType(event.target.value);
    if (editData) {
      setEditData((prevEditData) => ({
        ...prevEditData,
        data: {
          ...prevEditData.data,
          account_user: event.target.value,
        },
      }));
    }
  };
  
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  window.handleEditClick = async (id) => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
      const response = await fetch(`${baseURL}/get_ad/${id}`, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache', 
        },
      });

      if (!response.ok) {
        $('#loading').hide();
      }
      const data = await response.json();
      console.log(data.data)
      $('#loading').hide();
      setEditData(data); // Set the fetched data to state
      setShowModal(true); // Show the modal
    } catch (error) {
      console.error('Error fetching data:', error);
      $('#loading').hide();
      // Handle error scenarios if needed
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditData(null);
    setErrors({});
  };

  useEffect(() => {
    // Populate the form fields with the data received from the API
    if (editData) {
      console.log(editData.data.multiple_numbers);
      const receivedNumbers = editData.data.multiple_numbers || {};
      const numbersObject = JSON.parse(receivedNumbers);
      const numbersArray = Object.values(numbersObject) || [];
      setShowForm(true);
    console.log(numbersArray);
      setFormData({
        
        id: editData.data.id || '',
        
        facebook_page: editData.data.facebook_page || '',
        ad_id: editData.data.ad_id || '',
        builder_name: editData.data.builder_name || '',
        whats_app_number: editData.data.whats_app_number || '',
        multiple_numbers: numbersArray,
        published_date: editData.data.published_date || '',
        account_user : editData.data.account_user || '',
      });
      setNumbers(numbersArray);
      setBuilderTypes(editData.accounts);
      
    }
  }, [editData]);
  
  const handleAddNumber = () => {
    if (numbers.length < 4) {
      const updatedNumbers = [...numbers, ''];
      setNumbers(updatedNumbers);
    }
  };

  const handleRemoveNumber = (index) => {
    const updatedNumbers = [...numbers];
    updatedNumbers.splice(index, 1);
    setNumbers(updatedNumbers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};

if (!formData.builder_name.trim()) {
  newErrors.builder_name = 'Builder Name is required.';
}

if (!formData.facebook_page.trim()) {
  newErrors.facebook_page = 'Ad Name is required.';
}

// if (selectedBuilderType === '') {
//   newErrors.selectedBuilderType = 'Account Username is required.';
// }

if (!formData.ad_id.trim()) {
  newErrors.ad_id = 'Ad ID is required.';
}

if (!formData.whats_app_number.trim()) {
  newErrors.whats_app_number = 'WhatsApp Number is required.';
}

if (!formData.published_date) {
  newErrors.published_date = 'Publish Date is required.';
}
    
    //   setErrors(newErrors);
    // ... apply similar checks for other fields

    // If any errors are found, display them and prevent form submission
    if (Object.keys(newErrors).length > 0) {
        console.log("okaaaaaaaaayyyyyyyyy",newErrors)
      setErrors(newErrors);
      return;
    }
    event.preventDefault();
    const multipleNumberInputs = Array.from(event.target.getElementsByClassName('multiple-number-input'));
    const multipleNumbers = multipleNumberInputs
      .map((input) => input.value.trim())
      .filter((value) => value !== '');
    
      const whatsappNumbersObject = {};
      multipleNumbers.forEach((number, index) => {
        whatsappNumbersObject[`whats_app_no${index + 1}`] = number;
      });
      const multiplewhatsappno = JSON.stringify(whatsappNumbersObject);
      
      formData.multiple_numbers = multiplewhatsappno;

    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }
      
      const response = await fetch(`${baseURL}/update_ad_object/`, {
        method: 'PUT',
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (!response.ok) {
        $('#loading').hide();
        setErrors({"ad_id":data.message})
        console.error('Failed to update data');
        // Handle error scenarios if needed
        return;
      }
      
      console.log(`Status changed for ID: ${data.data}`);
      editData.data.account_user = null;
      const updatedAdItem = data.data;
      setSelectedBuilderType(data.data.account_user || '');

      const updatedAdItems = tableData.map(item => {
        if (item.id === updatedAdItem.id) {
          return updatedAdItem;
        }
        return item;
      });

      setTableData(updatedAdItems);
      $('#loading').hide();
      setShowModal(false);
      toastr.success(data.message);
      setErrors({});
      
    } catch (error) {
      console.error('Error updating data:', error);
      $('#loading').hide();
      setShowModal(false);
      // Handle error scenarios if needed
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        $('#loading').show();
        // Get the access token from localStorage
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
  
        const response = await fetch(`${baseURL}/get_dashboard/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json', 
            'Cache-Control': 'no-cache',
          },
        });
  
        if (!response.ok) {
          $('#loading').hide();
          // Handle error for non-2xx responses
          // navigate('/');
         
        }
  
        const data = await response.json();
        setTableData(data.data);
        $('#loading').hide();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  window.handleDelete = (id) => {
    $('#loading').show();

    if (id) {
      const accessToken = localStorage.getItem('access_token');
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();

        return;
      }
  
      setDeleteItemUsername(id);
      setShowDeleteConfirmation(true);
  
      fetch(`${baseURL}/delete_ad/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache',
        },
      })
        .then((response) => {
          if (!response.ok) {
            $('#loading').hide();

          }
          return response.json();
        })
        .then((data) => {
          console.log('Delete successful:', data);
          setTableData(data.data);
          setShowDeleteConfirmation(false);
          setDeleteItemUsername('');
          $('#loading').hide();

        })
        .catch((error) => {
          console.error('Error deleting item:', error);
          $('#loading').hide();

        });
    }
  };

  window.handleDeleteConfirm = (id) => {
    setDeleteItemUsername(id);
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
  let dataTable = null;

  if (!loading) {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      dataTable = $(tableRef.current).DataTable();
      dataTable.clear().destroy();
    }

    dataTable = $(tableRef.current).DataTable({
      data: tableData,
      columns: [
        { title: 'ID', data: 'id' },
        { title: 'Builder Name', data: 'builder_name' },
        { title: 'WhatsApp Number', data: 'whats_app_number' },
        { title: 'Status', data: 'status', render: status => status ? 'Active' : 'Inactive' },
        { title: 'Total Leads', data: 'total_lead_count' },
        { title: "PastLead", data: 'yesterday_lead_count' },
        {
          title: 'Actions',
          data: null,
          render: function (data, type, row) {
            return `
              <div class="form-check form-switch d-flex justify-content-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="statusSwitch-${row.id}"
                  ${row.status ? 'checked' : ''}
                  onchange="window.changeStatus(${row.id})"
                />
                <label class="form-check-label" for="statusSwitch-${row.id}"></label>
              </div>

            `;
          },
        },
        {
          title: '',
          data: null,
          render: function (data, type, row) {
            return `
              <div style="display: flex; justify-content: center; align-items: center;">
                <i class="fas fa-edit text-primary" style="cursor: pointer; margin-right: 10px;" title="Edit" onClick={window.handleEditClick('${row.id}')}></i>
                <i class="fas fa-trash-alt text-danger" style="cursor: pointer;" title="Delete" onclick="window.handleDeleteConfirm('${row.id}')"></i>
              </div>
            `;
          },
        },
        
      ],
      // Add other DataTable configurations as needed
    });
  }

  return () => {
    if (dataTable !== null) {
      dataTable.clear().destroy();
    }
  };
}, [loading, tableData]);

  window.changeStatus = async (id) => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
        console.log(accessToken,"token value")
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
        const response = await fetch(`${baseURL}/ad_status_change/${id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
          },
        });
      
  
      if (!response.ok) {
        $('#loading').hide();
      }
      const data = await response.json();
      console.log(`Status changed for ID: ${data.data}`);
      
      const updatedAdItem = data.data;

      // Update the local state
      const updatedAdItems = tableData.map(item => {
        if (item.id === updatedAdItem.id) {
          return updatedAdItem;
        }
        return item;
      });

      setTableData(updatedAdItems);
      $('#loading').hide();
      toastr.success('Ad Status Updated Successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      $('#loading').hide();
    }
  };
  

  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).find('thead th').css('background-color', '#9F9F9F'); // Setting header cell color
    }
  }, [tableData]);
  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <main className="col-md-9 ms-sm-auto col-lg-9 px-md-3">
          {/* Your Dashboard content goes here */}
          <h2 className="text-center mt-3">Welcome To The Dashboard</h2>
          <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>

            <div className="table-responsive">
              <table
                id="table_container"
                ref={tableRef}
                className="table table-bordered table-striped"
                style={{ width: '100%' }}
              />
            </div>
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
      Cancel
    </Button>
    <Button variant="danger" onClick={() => window.handleDelete(deleteItemUsername)}>
      Delete
    </Button>
  </Modal.Footer>
</Modal>
<Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update Ad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBuilderType">
  <strong>
    <Form.Label className="text-dark">Account<span style={{ 'color': "red" }}>*</span></Form.Label>
  </strong>
  <Form.Select
    aria-label="Select Builder Type"
    className={`mb-1 ${errors.selectedBuilderType ? 'border border-danger' : ''}`}
    value={selectedBuilderType || (editData && editData.data.account_user) || ''}
    onChange={handleBuilderTypeChange}
  >
    <option value="">Select Builder Type</option>
    {Array.isArray(builderTypes) &&
      builderTypes.map((type) => (
        <option key={type.id} value={type.username}>
          {type.username}
        </option>
      ))}
  </Form.Select>
  {errors.selectedBuilderType && (
    <div className="mb-1">
      <small className="text-danger">{errors.selectedBuilderType}</small>
    </div>
  )}
</Form.Group>

          <Form.Group controlId="adName">
          <Form.Label><strong>Ad Name</strong></Form.Label>
            <Form.Control
              type="text"
              value={formData.facebook_page}
              onChange={(e) => setFormData({ ...formData, facebook_page: e.target.value })}
            />
          </Form.Group>
          {errors.facebook_page && (
    <div className="mb-1">
      <small className="text-danger">{errors.facebook_page}</small>
    </div>
  )}

          <Form.Group controlId="adID">
          <Form.Label><strong>Ad ID</strong></Form.Label>
            <Form.Control
              type="text"
              value={formData.ad_id}
              onChange={(e) => setFormData({ ...formData, ad_id: e.target.value })}
              
            />
          </Form.Group>
          {errors.ad_id && (
    <div className="mb-1">
      <small className="text-danger">{errors.ad_id}</small>
    </div>
  )}

          <Form.Group controlId="builderName">
          <Form.Label><strong>Builder Name</strong></Form.Label>
            <Form.Control
              type="text"
              value={formData.builder_name}
              onChange={(e) => setFormData({ ...formData, builder_name: e.target.value })}
            />
          </Form.Group>
          {errors.builder_name && (
    <div className="mb-1">
      <small className="text-danger">{errors.builder_name}</small>
    </div>
  )}

          <Form.Group controlId="whatsAppNumber">
          <Form.Label><strong>WhatsApp Number</strong></Form.Label>
            <Form.Control
              type="text"
              value={formData.whats_app_number}
              onChange={(e) => setFormData({ ...formData, whats_app_number: e.target.value })}
            />
          </Form.Group>
          {errors.whats_app_number && (
    <div className="mb-1">
      <small className="text-danger">{errors.whats_app_number}</small>
    </div>
  )}

          {!showForm && (
        <Button variant="secondary" className={`mt-2`} onClick={toggleForm}>
          Add Number
        </Button>
      )}
{/* 
          {showForm && (
  <Form.Group controlId="formMultipleNumbers">
    {numbers.map((number, index) => (
      <Row key={index} className="mb-3">
        <Col md={9}>
          <Form.Control 
            type="text"
            className={`multiple-number-input mt-1`}
            placeholder={`Enter Number ${index + 1}`}
            value={number}
            onChange={(e) => {
              const updatedNumbers = [...numbers];
              updatedNumbers[index] = e.target.value;
              setNumbers(updatedNumbers);
            }} 
            
          />
        </Col>
        <Col md={3}>
        <Button
  variant="danger" className={`mt-1`}
  onClick={() => handleRemoveNumber(index)}
  disabled={numbers.length === 0} // Disable Remove button only if there's a single input field
>
  Remove
</Button>
        </Col>
      </Row>
    ))}
    {numbers.length < 4 && (
      <Button  variant="secondary" className='mt-2' onClick={handleAddNumber}>
        Add Number
      </Button>
    )}
  </Form.Group>
)} */}
{showForm && (
  <Form.Group controlId="formMultipleNumbers" id="multinumbers">
    {numbers.map((number, index) => (
      <Row key={index} className="mb-3">
        <Col md={9}>
          <Form.Control 
            type="text"
            className={`multiple-number-input mt-1`}
            placeholder={`Enter Number ${index + 1}`}
            value={number}
            onChange={(e) => {
              const updatedNumbers = [...numbers];
              updatedNumbers[index] = e.target.value;
              setNumbers(updatedNumbers);
            }} 
          />
        </Col>
        <Col md={3}>
          <Button
            variant="danger"
            className={`mt-1`}
            onClick={() => handleRemoveNumber(index)}
            disabled={numbers.length === 0} // Disable Remove button only if there's a single input field
          >
            Remove
          </Button>
        </Col>
      </Row>
    ))}
    {numbers.length < 4 && (
      <Button variant="secondary" className='mt-2' onClick={handleAddNumber}>
        Add Number
      </Button>
    )}
  </Form.Group>
)}

          <Form.Group controlId="publishDate">
          <Form.Label><strong>Publish Date</strong></Form.Label>
            <Form.Control
              type="date"
              value={formData.published_date}
              onChange={(e) => setFormData({ ...formData, published_date: e.target.value })}
            />
          </Form.Group>
          {errors.published_date && (
    <div className="mb-1">
      <small className="text-danger">{errors.published_date}</small>
    </div>
  )}

          <Button variant="primary"  className={`mt-2`} type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
          {/* Define your custom styles here */}
          <style>
            {`
              /* Example styles for toggle switch */
              .form-switch .form-check-input:checked {
                background-color: #567ee9;
              }

              .form-switch .form-check-input:not(:checked) {
                background-color: #a6a6a6;
              }
            `}
          </style>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
