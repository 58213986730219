import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // React Router ka use karna hoga

const Sidebar = () => {
  const location = useLocation(); // Current URL ko track karne ke liye use kiya gaya hai
  const [activeLink, setActiveLink] = useState(location.pathname); // Default active link set kiya gaya hai

  useEffect(() => {
    setActiveLink(location.pathname); // URL change hone par active link update karega
  }, [location.pathname]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark vh-100" style={{ width: '280px' }}>
      
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <a href="/home" className={`nav-link text-white ${activeLink === '/home' ? 'active' : ''}`} onClick={() => handleLinkClick('/home')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Home
          </a>
        </li>
        
        
        
        <li className="nav-item">
          <a href="/accounts" className={`nav-link text-white ${activeLink === '/accounts' ? 'active' : ''}`} onClick={() => handleLinkClick('/accounts')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Accounts
          </a>
        </li>
        
        <li className="nav-item">
          <a href="/add_page" className={`nav-link text-white ${activeLink === '/add_page' ? 'active' : ''}`} onClick={() => handleLinkClick('/add_page')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Add Facebook Page
          </a>
        </li>
        <li className="nav-item">
          <a href="/import_leads" className={`nav-link text-white ${activeLink === '/import_leads' ? 'active' : ''}`} onClick={() => handleLinkClick('/import_leads')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Import Leads
          </a>
        </li>
        <li className="nav-item">
          <a href="/send_leads" className={`nav-link text-white ${activeLink === '/send_leads' ? 'active' : ''}`} onClick={() => handleLinkClick('/send_leads')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Send Leads
          </a>
        </li>
        <li className="nav-item">
          <a href="/fetch_leads" className={`nav-link text-white ${activeLink === '/fetch_leads' ? 'active' : ''}`} onClick={() => handleLinkClick('/fetch_leads')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Fetch Leads
          </a>
        </li>
        <li className="nav-item">
          <a href="/download_leads" className={`nav-link text-white ${activeLink === '/download_leads' ? 'active' : ''}`} onClick={() => handleLinkClick('/download_leads')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Download Leads
          </a>
        </li>
        <li className="nav-item">
          <a href="/analysis" className={`nav-link text-white ${activeLink === '/analysis' ? 'active' : ''}`} onClick={() => handleLinkClick('/analysis')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Analysis
          </a>
        </li>
        {/* <li className="nav-item">
          <a href="/gruhkhoj" className={`nav-link text-white ${activeLink === '/gruhkhoj' ? 'active' : ''}`} onClick={() => handleLinkClick('/gruhkhoj')}>
            <svg className="bi me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Gruhkhoj
          </a>
        </li> */}
        
        {/* Baaki sidebar links ko bhi similar tarike se add kare */}
      </ul>
      <hr />
      
    </div>
  );
};

export default Sidebar;
